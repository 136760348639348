import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { Title, } from "gatsby-theme-material-foundry"
import { Container, Box, makeStyles } from "@material-ui/core"
import SEO from "../components/SEO/seo"
import { ThemeProvider } from "@material-ui/styles"
import { useTheme } from "@material-ui/styles"
import BackgroundImage from "gatsby-background-image"

const useStyles = makeStyles(theme => ({
  hero: {
    color: "white",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
      paddingBottom: "50px",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "50px",
      paddingBottom: "50px",
    },
  },
  bigAvatar: {
    width: 100,
    height: 100,
  },
  heroTitle: {
    color: "white",
    textShadow: "2px 2px 4px #000000",
    "&:hover":{
        color: theme.palette.secondary.main
    }
  },
}))

function NewsTemplate(props) {
  const campus = props.data.allCampus.nodes
  const theme = useTheme()
  const classes = useStyles()

  return (
    <ThemeProvider theme={theme}>
      <Layout dark>
        <SEO postTitle={`Alle campus`} slug={`campus`} />
        {campus &&
          campus.map(c => (
            <BackgroundImage alt={c.name} fluid={c.image.childImageSharp.fluid}>
              <Box align="center" py={16}>
                <Container maxWidth="md">
                  <Box pt={12}>
                      <Link to={`/campus/${c.slug}`} style={{textDecoration: "none"}}>
                    <Title
                      variant="h1"
                      gutterBottom
                      className={classes.heroTitle}
                    >
                      {c.name}
                    </Title>
                    </Link>
                  </Box>
                </Container>
              </Box>
            </BackgroundImage>
          ))}
      </Layout>
    </ThemeProvider>
  )
}

export default NewsTemplate

export const ItemPageQuery = graphql`
  query allCampus {
    allCampus {
      nodes {
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        address
        name
        slug
        id
      }
    }
  }
`
